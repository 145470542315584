import { useRef } from "react";
import * as THREE from "three";
import { useEntityMovement } from "../lib/useEntityMovement";

const PavementLayer = () => {
  const vehicleRef = useRef<THREE.Group>(null);
  const augerRef = useRef<THREE.Mesh>(null);
  const screedRef = useRef<THREE.Group>(null);

  useEntityMovement({
    refs: {
      vehicleRef,
      animationRef: augerRef,
    },
    onMove: (delta) => {
      if (augerRef.current) {
        augerRef.current.rotation.z += delta * 0;
      }
      if (screedRef.current) {
        // Vibrate the screed
        screedRef.current.position.y = Math.sin(Date.now() * 0.05) * 0.02;
      }
    },
  });

  return (
    <group ref={vehicleRef}>
      {/* Main hopper and body */}
      <mesh position={[0, 0.6, -0.2]} castShadow>
        <boxGeometry args={[1.8, 0.8, 1.4]} />
        <meshStandardMaterial color="#FFD700" />
      </mesh>

      {/* Operator platform */}
      <mesh position={[0, 1.0, -0.8]} castShadow>
        <boxGeometry args={[1.4, 0.2, 0.6]} />
        <meshStandardMaterial color="#333333" />
      </mesh>

      {/* Control console */}
      <mesh position={[0, 1.2, -0.8]} castShadow>
        <boxGeometry args={[0.4, 0.2, 0.3]} />
        <meshStandardMaterial color="#666666" />
      </mesh>

      {/* Auger system */}
      <group position={[0, 0.3, 0.4]}>
        <mesh ref={augerRef} castShadow>
          <cylinderGeometry args={[0.2, 0.2, 1.6, 16, 8, true]} />
          <meshStandardMaterial color="#666666" metalness={0.8} />
        </mesh>
        {/* Auger blades */}
        {Array.from({ length: 12 }).map((_, i) => (
          <mesh
            key={i}
            position={[-0.8 + (i * 1.6) / 11, 0, 0]}
            rotation={[0, 0, (Math.PI * 2 * i) / 12]}
            castShadow
          >
            <boxGeometry args={[0.05, 0.15, 0.05]} />
            <meshStandardMaterial color="#444444" metalness={0.9} />
          </mesh>
        ))}
      </group>

      {/* Screed */}
      <group ref={screedRef} position={[0, 0.2, 0.6]}>
        {/* Main screed plate */}
        <mesh castShadow>
          <boxGeometry args={[1.8, 0.1, 0.4]} />
          <meshStandardMaterial color="#444444" metalness={0.6} />
        </mesh>
        {/* Heating elements */}
        {[-0.6, 0, 0.6].map((x) => (
          <mesh key={x} position={[x, -0.05, 0]} castShadow>
            <boxGeometry args={[0.4, 0.05, 0.3]} />
            <meshStandardMaterial
              color="#FF4400"
              emissive="#FF2200"
              emissiveIntensity={0.5}
            />
          </mesh>
        ))}
        {/* Tamper bar */}
        <mesh position={[0, 0, 0.2]} castShadow>
          <boxGeometry args={[1.7, 0.08, 0.05]} />
          <meshStandardMaterial color="#666666" metalness={0.7} />
        </mesh>
      </group>

      {/* Tracks */}
      {[-0.8, 0.8].map((x) => (
        <group key={x}>
          {/* Main track body */}
          <mesh position={[x, 0.3, 0]} castShadow>
            <boxGeometry args={[0.2, 0.6, 1.8]} />
            <meshStandardMaterial color="#333333" />
          </mesh>
          {/* Track details */}
          {Array.from({ length: 6 }).map((_, i) => (
            <mesh key={i} position={[x, 0.3, -0.9 + (i * 1.8) / 5]} castShadow>
              <boxGeometry args={[0.22, 0.58, 0.1]} />
              <meshStandardMaterial color="#1C1C1C" />
            </mesh>
          ))}
        </group>
      ))}

      {/* Material flow guides */}
      {[-0.7, 0.7].map((x) => (
        <mesh
          key={x}
          position={[x, 0.4, 0.2]}
          rotation={[Math.PI / 6, 0, 0]}
          castShadow
        >
          <boxGeometry args={[0.1, 0.4, 0.6]} />
          <meshStandardMaterial color="#FFD700" />
        </mesh>
      ))}
    </group>
  );
};

export default PavementLayer;
