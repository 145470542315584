import { useRef } from "react";
import * as THREE from "three";
import { useEntityMovement } from "../lib/useEntityMovement";

const Painter = () => {
  const vehicleRef = useRef<THREE.Group>(null);
  const sprayerRef = useRef<THREE.Mesh>(null);
  const wheelRefs = useRef<THREE.Mesh[]>([]);

  useEntityMovement({
    refs: {
      vehicleRef,
      animationRef: sprayerRef,
    },
    onMove: (delta) => {
      if (sprayerRef.current) {
        // Subtle spray nozzle movement
        sprayerRef.current.rotation.x = Math.sin(Date.now() * 0.01) * 0.1;
      }
      // Rotate wheels when moving
      wheelRefs.current.forEach((wheel) => {
        if (wheel) {
          wheel.rotation.x += delta * 2;
        }
      });
    },
  });

  return (
    <group ref={vehicleRef}>
      {/* Main vehicle body */}
      <mesh position={[0, 0.5, 0]} castShadow>
        <boxGeometry args={[1.2, 0.6, 1.6]} />
        <meshStandardMaterial color="#FFFFFF" />
      </mesh>

      {/* Paint tank */}
      <mesh position={[0, 0.8, -0.2]} castShadow>
        <cylinderGeometry args={[0.4, 0.4, 0.8, 16]} />
        <meshStandardMaterial color="#C0C0C0" metalness={0.6} />
      </mesh>
      {/* Tank cap */}
      <mesh position={[0, 1.2, -0.2]} castShadow>
        <cylinderGeometry args={[0.2, 0.2, 0.1, 16]} />
        <meshStandardMaterial color="#666666" />
      </mesh>

      {/* Operator seat */}
      <group position={[0, 0.6, -0.5]}>
        <mesh castShadow>
          <boxGeometry args={[0.6, 0.1, 0.6]} />
          <meshStandardMaterial color="#333333" />
        </mesh>
        <mesh position={[0, 0.3, 0.2]} castShadow>
          <boxGeometry args={[0.6, 0.4, 0.1]} />
          <meshStandardMaterial color="#333333" />
        </mesh>
      </group>

      {/* Control panel */}
      <mesh position={[0, 0.8, -0.6]} castShadow>
        <boxGeometry args={[0.4, 0.2, 0.3]} />
        <meshStandardMaterial color="#666666" />
      </mesh>

      {/* Spray system */}
      <group position={[0, 0.2, 0.6]}>
        {/* Main spray bar */}
        <mesh ref={sprayerRef} position={[0, 0, 0]} castShadow>
          <boxGeometry args={[0.8, 0.1, 0.2]} />
          <meshStandardMaterial color="#333333" />
        </mesh>
        {/* Spray nozzles */}
        {[-0.3, 0, 0.3].map((x) => (
          <group key={x}>
            <mesh position={[x, -0.05, 0]} castShadow>
              <cylinderGeometry args={[0.03, 0.03, 0.1, 8]} />
              <meshStandardMaterial color="#666666" />
            </mesh>
            {/* Nozzle tips */}
            <mesh position={[x, -0.1, 0]} castShadow>
              <cylinderGeometry args={[0.02, 0.01, 0.02, 8]} />
              <meshStandardMaterial color="#444444" metalness={0.8} />
            </mesh>
          </group>
        ))}
      </group>

      {/* Wheels with suspension */}
      {[
        [-0.5, 0.3, 0.5],
        [0.5, 0.3, 0.5],
        [-0.5, 0.3, -0.5],
        [0.5, 0.3, -0.5],
      ].map(([x, y, z], i) => (
        <group key={i} position={[x, y, z]}>
          {/* Suspension */}
          <mesh position={[0, 0.1, 0]} castShadow>
            <cylinderGeometry args={[0.05, 0.05, 0.2, 8]} />
            <meshStandardMaterial color="#666666" />
          </mesh>
          {/* Wheel */}
          <mesh
            ref={(el) => {
              if (el) wheelRefs.current[i] = el;
            }}
            rotation={[0, 0, Math.PI / 2]}
            castShadow
          >
            <cylinderGeometry args={[0.3, 0.3, 0.2, 16]} />
            <meshStandardMaterial color="#333333" />
          </mesh>
          {/* Wheel hub */}
          <mesh
            position={[x > 0 ? 0.1 : -0.1, 0, 0]}
            rotation={[0, 0, Math.PI / 2]}
            castShadow
          >
            <cylinderGeometry args={[0.1, 0.1, 0.05, 8]} />
            <meshStandardMaterial color="#666666" metalness={0.8} />
          </mesh>
        </group>
      ))}

      {/* Paint supply lines */}
      {[-0.2, 0.2].map((x) => (
        <mesh
          key={x}
          position={[x, 0.4, 0.2]}
          rotation={[Math.PI / 6, 0, 0]}
          castShadow
        >
          <cylinderGeometry args={[0.02, 0.02, 0.6, 8]} />
          <meshStandardMaterial color="#666666" />
        </mesh>
      ))}
    </group>
  );
};

export default Painter;
