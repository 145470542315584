import { create } from "zustand";

export enum GroundState {
  Earth = 0,
  Tilled = 1,
  Subbase = 2,
  Paved = 3,
  Painted = 4,
}

export enum Equipment {
  Tiller = "milling",
  SubbaseLayer = "base_course",
  PavementLayer = "paver",
  Roller = "roller",
  Painter = "striper",
}

interface Point {
  x: number;
  z: number;
}

interface Mission {
  start: Point;
  end: Point;
  completed: boolean;
  score: number;
}

// Map equipment to the state they can create
const EQUIPMENT_CAPABILITIES = {
  [Equipment.Tiller]: GroundState.Tilled,
  [Equipment.SubbaseLayer]: GroundState.Subbase,
  [Equipment.PavementLayer]: GroundState.Paved,
  [Equipment.Roller]: GroundState.Paved,
  [Equipment.Painter]: GroundState.Painted,
};

interface Block {
  x: number;
  z: number;
  state: GroundState;
  isRolled: boolean;
  hasHouse?: boolean;
  buildingTier?: number;
}

interface GameState {
  blocks: Block[][];
  gridSize: number;
  rollerPosition: Point;
  currentEquipment: Equipment;
  currentMission: Mission | null;
  totalScore: number;
  completedMissions: number;
  initGame: () => void;
  updateBlock: (x: number, z: number) => void;
  moveRoller: (x: number, z: number) => void;
  setEquipment: (equipment: Equipment) => void;
  startNewMission: () => void;
  checkMissionProgress: () => void;
  generateHouses: () => void;
}

const GRID_SIZE = 40;

const generateMissionPoints = (): { start: Point; end: Point } => {
  // Generate points at least 8 blocks apart (scaled up from 5 for larger grid)
  const margin = 6; // Keep points away from edges
  const start = {
    x: margin + Math.floor(Math.random() * (GRID_SIZE - 2 * margin)),
    z: margin + Math.floor(Math.random() * (GRID_SIZE - 2 * margin)),
  };

  let end;
  do {
    end = {
      x: margin + Math.floor(Math.random() * (GRID_SIZE - 2 * margin)),
      z: margin + Math.floor(Math.random() * (GRID_SIZE - 2 * margin)),
    };
  } while (Math.abs(end.x - start.x) < 8 || Math.abs(end.z - start.z) < 8);

  return { start, end };
};

const useGameStore = create<GameState>((set, get) => ({
  blocks: [],
  gridSize: GRID_SIZE,
  rollerPosition: { x: 0, z: 0 },
  currentEquipment: Equipment.Tiller,
  currentMission: null,
  totalScore: 0,
  completedMissions: 0,

  initGame: () => {
    const blocks = Array(GRID_SIZE)
      .fill(0)
      .map((_, x) =>
        Array(GRID_SIZE)
          .fill(0)
          .map((_, z) => ({
            x,
            z,
            state: GroundState.Earth,
            isRolled: false,
          }))
      );
    set({ blocks, totalScore: 0, completedMissions: 0 });
    get().startNewMission();
  },

  startNewMission: () => {
    const { start, end } = generateMissionPoints();
    set({
      currentMission: {
        start,
        end,
        completed: false,
        score: 0,
      },
    });
  },

  generateHouses: () => {
    const { blocks, currentMission, completedMissions } = get();
    if (!currentMission?.completed) return;

    // Find all valid spots adjacent to painted road blocks
    const validSpots: Point[] = [];
    const directions = [
      [-1, 0],
      [1, 0],
      [0, -1],
      [0, 1],
    ];

    blocks.forEach((row, x) => {
      row.forEach((block, z) => {
        // If this is a painted road block
        if (block.state === GroundState.Painted) {
          // Check adjacent spots
          for (const [dx, dz] of directions) {
            const newX = x + dx;
            const newZ = z + dz;

            // Check if spot is valid (within bounds, is earth, and doesn't already have a house)
            if (
              newX >= 0 &&
              newX < GRID_SIZE &&
              newZ >= 0 &&
              newZ < GRID_SIZE &&
              blocks[newX][newZ].state === GroundState.Earth &&
              !blocks[newX][newZ].hasHouse
            ) {
              // Check if this spot isn't already in our list
              if (
                !validSpots.some((spot) => spot.x === newX && spot.z === newZ)
              ) {
                validSpots.push({ x: newX, z: newZ });
              }
            }
          }
        }
      });
    });

    // Randomly select 3-4 spots for houses
    const numHouses = Math.min(
      3 + Math.floor(Math.random() * 2),
      validSpots.length
    );
    const housePositions: Point[] = [];
    while (housePositions.length < numHouses && validSpots.length > 0) {
      const index = Math.floor(Math.random() * validSpots.length);
      const position = validSpots.splice(index, 1)[0];
      housePositions.push(position);
    }

    // Calculate building tier based on completed missions
    const tier = Math.min(Math.floor(completedMissions / 2), 6);

    // Update blocks with houses
    set((state) => {
      const newBlocks = [...state.blocks];
      housePositions.forEach(({ x, z }) => {
        newBlocks[x][z] = {
          ...newBlocks[x][z],
          hasHouse: true,
          buildingTier: tier,
        };
      });
      return { blocks: newBlocks };
    });
  },

  checkMissionProgress: () => {
    const { blocks, currentMission } = get();
    if (!currentMission || currentMission.completed) return;

    // Check if there's a valid path of painted road from start to end
    const visited = new Set<string>();
    const queue: Point[] = [currentMission.start];

    while (queue.length > 0) {
      const current = queue.shift()!;
      const key = `${current.x},${current.z}`;

      if (visited.has(key)) continue;
      visited.add(key);

      if (
        current.x === currentMission.end.x &&
        current.z === currentMission.end.z
      ) {
        // Path found! Calculate score and generate houses
        const score = Math.floor(
          1000 *
            (visited.size /
              (Math.abs(currentMission.end.x - currentMission.start.x) +
                Math.abs(currentMission.end.z - currentMission.start.z)))
        );
        set((state) => ({
          currentMission: {
            ...currentMission,
            completed: true,
            score,
          },
          totalScore: state.totalScore + score,
          completedMissions: state.completedMissions + 1,
        }));
        // Generate houses after a short delay, then start new mission
        setTimeout(() => {
          get().generateHouses();
          // Start new mission after houses are generated
          setTimeout(() => get().startNewMission(), 1500);
        }, 500);
        return;
      }

      // Check adjacent blocks
      const directions = [
        [-1, 0],
        [1, 0],
        [0, -1],
        [0, 1],
      ];
      for (const [dx, dz] of directions) {
        const newX = current.x + dx;
        const newZ = current.z + dz;

        if (
          newX >= 0 &&
          newX < GRID_SIZE &&
          newZ >= 0 &&
          newZ < GRID_SIZE &&
          blocks[newX][newZ].state === GroundState.Painted
        ) {
          queue.push({ x: newX, z: newZ });
        }
      }
    }
  },

  updateBlock: (x: number, z: number) => {
    set((state) => {
      const newBlocks = [...state.blocks];
      if (newBlocks[x] && newBlocks[x][z] && !newBlocks[x][z].hasHouse) {
        const currentState = newBlocks[x][z].state;
        const targetState = EQUIPMENT_CAPABILITIES[state.currentEquipment];

        // Equipment can only work on appropriate states and not on blocks with houses
        const canWork =
          (state.currentEquipment === Equipment.Tiller &&
            currentState === GroundState.Earth) ||
          (state.currentEquipment === Equipment.SubbaseLayer &&
            currentState === GroundState.Tilled) ||
          (state.currentEquipment === Equipment.PavementLayer &&
            currentState === GroundState.Subbase) ||
          (state.currentEquipment === Equipment.Roller &&
            currentState === GroundState.Paved &&
            !newBlocks[x][z].isRolled) ||
          (state.currentEquipment === Equipment.Painter &&
            currentState === GroundState.Paved &&
            newBlocks[x][z].isRolled);

        if (canWork) {
          newBlocks[x][z] = {
            ...newBlocks[x][z],
            state: targetState,
            isRolled: state.currentEquipment === Equipment.Roller,
          };
          setTimeout(() => get().checkMissionProgress(), 0);
        }
      }
      return { blocks: newBlocks };
    });
  },

  moveRoller: (x: number, z: number) => {
    set({ rollerPosition: { x, z } });
  },

  setEquipment: (equipment: Equipment) => {
    set({ currentEquipment: equipment });
  },
}));

export default useGameStore;
