import { Canvas } from "@react-three/fiber";
import { Sky, OrbitControls, KeyboardControls } from "@react-three/drei";
import { PCFSoftShadowMap } from "three";
import Game from "./game/Game";
import EquipmentSelector from "./components/EquipmentSelector";
import TouchControls from "./components/TouchControls";
import { useEffect } from "react";
import "./App.css";

function App() {
  // Add mobile meta tags
  useEffect(() => {
    // Add viewport meta tag
    const viewport = document.createElement("meta");
    viewport.name = "viewport";
    viewport.content =
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no";
    document.head.appendChild(viewport);

    // Add mobile web app capable meta tags
    const webAppCapable = document.createElement("meta");
    webAppCapable.name = "apple-mobile-web-app-capable";
    webAppCapable.content = "yes";
    document.head.appendChild(webAppCapable);

    const mobileWebAppCapable = document.createElement("meta");
    mobileWebAppCapable.name = "mobile-web-app-capable";
    mobileWebAppCapable.content = "yes";
    document.head.appendChild(mobileWebAppCapable);

    // Cleanup
    return () => {
      document.head.removeChild(viewport);
      document.head.removeChild(webAppCapable);
      document.head.removeChild(mobileWebAppCapable);
    };
  }, []);

  return (
    <KeyboardControls
      map={[
        { name: "forward", keys: ["ArrowUp", "KeyW"] },
        { name: "backward", keys: ["ArrowDown", "KeyS"] },
        { name: "left", keys: ["ArrowLeft", "KeyA"] },
        { name: "right", keys: ["ArrowRight", "KeyD"] },
      ]}
    >
      <div className="game-container">
        <Canvas
          shadows={{ enabled: true, type: PCFSoftShadowMap }}
          camera={{ position: [15, 15, 15], fov: 60 }}
        >
          <Sky sunPosition={[100, 100, 20]} />
          <ambientLight intensity={0.8} />
          <directionalLight
            castShadow
            position={[2.5, 8, 5]}
            intensity={1.5}
            shadow-mapSize={[1024, 1024]}
          />
          <Game />
          <OrbitControls
            maxPolarAngle={Math.PI / 2.1}
            enablePan={false}
            enableZoom={true}
            minDistance={5}
            maxDistance={30}
          />
        </Canvas>
        <EquipmentSelector />
        <TouchControls />
      </div>
    </KeyboardControls>
  );
}

export default App;
