import { useRef } from "react";
import * as THREE from "three";
import { useEntityMovement } from "../lib/useEntityMovement";

const Roller = () => {
  const vehicleRef = useRef<THREE.Group>(null);
  const wheelRef = useRef<THREE.Mesh>(null);
  const backWheelRef = useRef<THREE.Mesh>(null);

  useEntityMovement({
    refs: {
      vehicleRef,
      animationRef: wheelRef,
    },
    onMove: (delta) => {
      if (wheelRef.current && backWheelRef.current) {
        const wheelSpeed = 10;
        wheelRef.current.rotateY(wheelSpeed * delta);
        backWheelRef.current.rotateY(wheelSpeed * delta);
      }
    },
  });

  return (
    <group ref={vehicleRef}>
      {/* Front roller */}
      <group position={[0, 0.7, 0.5]} rotation={[0, 0, Math.PI / 2]}>
        <mesh ref={wheelRef} castShadow receiveShadow>
          <cylinderGeometry args={[0.7, 0.7, 1.4, 32]} />
          <meshStandardMaterial
            color="#1C1C1C"
            metalness={0.6}
            roughness={0.2}
          />
        </mesh>
      </group>

      {/* Back roller */}
      <group position={[0, 0.7, -0.5]} rotation={[0, 0, Math.PI / 2]}>
        <mesh ref={backWheelRef} castShadow receiveShadow>
          <cylinderGeometry args={[0.7, 0.7, 1.4, 32]} />
          <meshStandardMaterial
            color="#1C1C1C"
            metalness={0.6}
            roughness={0.2}
          />
        </mesh>
      </group>

      {/* Vehicle body */}
      <group position={[0, 1.0, 0]}>
        {/* Main cabin */}
        <mesh castShadow>
          <boxGeometry args={[0.8, 1.8, 1]} />
          <meshStandardMaterial color="#FFD700" />
        </mesh>
        {/* Window */}
        <mesh position={[0, 0.4, 0]} castShadow>
          <boxGeometry args={[0.82, 0.6, 0.8]} />
          <meshStandardMaterial color="#4444FF" opacity={0.5} transparent />
        </mesh>
        {/* Front grill */}
        <mesh position={[0, -0.15, 0.55]} castShadow>
          <boxGeometry args={[0.7, 0.3, 0.1]} />
          <meshStandardMaterial color="#1C1C1C" />
        </mesh>
      </group>
    </group>
  );
};

export default Roller;
