import useGameStore, { GroundState } from "../../store/gameStore";
import { RepeatWrapping, DataTexture, RGBAFormat, FloatType } from "three";
import { useMemo } from "react";
import House, { BuildingTier } from "./House";

// Create a noise texture for gravel and asphalt
const createNoiseTexture = (
  size: number,
  scale: number,
  contrast: number,
  frequency: number = 1
) => {
  const data = new Float32Array(size * size * 4);
  for (let i = 0; i < size * size; i++) {
    const x = (i % size) / size;
    const y = Math.floor(i / size) / size;
    const noise =
      Math.random() * contrast +
      Math.sin(x * Math.PI * frequency) *
        Math.sin(y * Math.PI * frequency) *
        (contrast * 0.5);
    data[i * 4] = noise;
    data[i * 4 + 1] = noise;
    data[i * 4 + 2] = noise;
    data[i * 4 + 3] = 1;
  }
  const texture = new DataTexture(data, size, size, RGBAFormat, FloatType);
  texture.wrapS = texture.wrapT = RepeatWrapping;
  texture.repeat.set(scale, scale);
  texture.needsUpdate = true;
  return texture;
};

const GROUND_COLORS = {
  [GroundState.Earth]: "#8B4513", // Brown
  [GroundState.Tilled]: "#654321", // Dark Brown
  [GroundState.Subbase]: "#808080", // Gray for gravel
  [GroundState.Paved]: "#2F4F4F", // Dark Gray for asphalt
  [GroundState.Painted]: "#1a1a1a", // Almost Black for painted asphalt
};

const GROUND_HEIGHTS = {
  [GroundState.Earth]: 0.2,
  [GroundState.Tilled]: 0.15,
  [GroundState.Subbase]: 0.12,
  [GroundState.Paved]: 0.1,
  [GroundState.Painted]: 0.1,
};

interface RoadMarkingsProps {
  position: [number, number, number];
  roadType: "vertical" | "horizontal" | "corner" | "intersection" | "end";
  rotation?: number;
}

const RoadMarkings = ({
  position,
  roadType,
  rotation = 0,
}: RoadMarkingsProps) => {
  const renderStraightRoad = () => (
    <>
      {/* Center dotted lines */}
      {[-0.35, -0.175, 0, 0.175, 0.35].map((z) => (
        <mesh
          key={`center-${z}`}
          position={[0, 0.001, z]}
          rotation={[-Math.PI / 2, 0, 0]}
        >
          <planeGeometry args={[0.05, 0.15]} />
          <meshStandardMaterial color="#FFFFFF" />
        </mesh>
      ))}

      {/* Outer solid lines */}
      <mesh position={[-0.45, 0.001, 0]} rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[0.05, 1.0]} />
        <meshStandardMaterial color="#FFFFFF" />
      </mesh>
      <mesh position={[0.45, 0.001, 0]} rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[0.05, 1.0]} />
        <meshStandardMaterial color="#FFFFFF" />
      </mesh>
    </>
  );

  const renderCorner = () => (
    <>
      {/* Vertical line (e.g., for north connection) */}
      <mesh position={[-0.45, 0.001, 0.25]} rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[0.05, 0.5]} />
        <meshStandardMaterial color="#FFFFFF" />
      </mesh>
      {/* Horizontal line (e.g., for east connection) */}
      <mesh
        position={[0.25, 0.001, 0.45]}
        rotation={[-Math.PI / 2, Math.PI / 2, 0]}
      >
        <planeGeometry args={[0.05, 0.5]} />
        <meshStandardMaterial color="#FFFFFF" />
      </mesh>
    </>
  );

  const renderIntersection = () => (
    <>
      {/* Horizontal lines */}
      <mesh position={[-0.45, 0.001, 0]} rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[0.05, 1.0]} />
        <meshStandardMaterial color="#FFFFFF" />
      </mesh>
      <mesh position={[0.45, 0.001, 0]} rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[0.05, 1.0]} />
        <meshStandardMaterial color="#FFFFFF" />
      </mesh>

      {/* Vertical lines */}
      <mesh position={[0, 0.001, -0.45]} rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[1.0, 0.05]} />
        <meshStandardMaterial color="#FFFFFF" />
      </mesh>
      <mesh position={[0, 0.001, 0.45]} rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[1.0, 0.05]} />
        <meshStandardMaterial color="#FFFFFF" />
      </mesh>

      {/* Center dotted lines */}
      {[-0.225, 0.225].map((x) => (
        <mesh
          key={`v-${x}`}
          position={[x, 0.001, 0]}
          rotation={[-Math.PI / 2, 0, 0]}
        >
          <planeGeometry args={[0.05, 0.45]} />
          <meshStandardMaterial color="#FFFFFF" />
        </mesh>
      ))}
      {[-0.225, 0.225].map((z) => (
        <mesh
          key={`h-${z}`}
          position={[0, 0.001, z]}
          rotation={[-Math.PI / 2, 0, 0]}
        >
          <planeGeometry args={[0.45, 0.05]} />
          <meshStandardMaterial color="#FFFFFF" />
        </mesh>
      ))}
    </>
  );

  return (
    <group position={position} rotation={[0, rotation, 0]}>
      {roadType === "vertical" && renderStraightRoad()}
      {roadType === "horizontal" && renderStraightRoad()}
      {roadType === "corner" && renderCorner()}
      {roadType === "intersection" && renderIntersection()}
    </group>
  );
};

const Ground = () => {
  const blocks = useGameStore((state) => state.blocks);
  const gridSize = useGameStore((state) => state.gridSize);

  // Create procedural textures for different surfaces
  const textures = useMemo(
    () => ({
      grass: createNoiseTexture(256, 32, 0.15, 4),
      gravel: createNoiseTexture(128, 4, 0.3),
      asphalt: createNoiseTexture(128, 8, 0.15),
      rolledAsphalt: createNoiseTexture(128, 16, 0.08),
    }),
    []
  );

  const getRoadConfig = (
    x: number,
    z: number
  ): { type: RoadMarkingsProps["roadType"]; rotation: number } => {
    const hasNorth = blocks[x]?.[z - 1]?.state === GroundState.Painted;
    const hasSouth = blocks[x]?.[z + 1]?.state === GroundState.Painted;
    const hasEast = blocks[x + 1]?.[z]?.state === GroundState.Painted;
    const hasWest = blocks[x - 1]?.[z]?.state === GroundState.Painted;

    const connections = [hasNorth, hasEast, hasSouth, hasWest].filter(
      Boolean
    ).length;

    if (connections === 4) {
      return { type: "intersection", rotation: 0 };
    }

    if (connections === 3) {
      return { type: "intersection", rotation: 0 };
    }

    if (connections === 2) {
      // Straight road
      if ((hasNorth && hasSouth) || (hasEast && hasWest)) {
        return {
          type: hasNorth && hasSouth ? "vertical" : "horizontal",
          rotation: hasNorth && hasSouth ? 0 : Math.PI / 2,
        };
      }
      // Corner
      return {
        type: "corner",
        rotation:
          hasNorth && hasEast
            ? Math.PI / 2
            : hasEast && hasSouth
            ? Math.PI
            : hasSouth && hasWest
            ? (3 * Math.PI) / 2
            : 0,
      };
    }

    // Default to horizontal for single connections or endpoints
    return {
      type: "horizontal",
      rotation: hasNorth || hasSouth ? 0 : Math.PI / 2,
    };
  };

  return (
    <group>
      {/* Extended grass background */}
      <mesh
        rotation={[-Math.PI / 2, 0, 0]}
        position={[0, -0.6, 0]}
        receiveShadow
      >
        <planeGeometry args={[1000, 1000]} />
        <meshStandardMaterial
          color="#4a8505"
          roughness={0.8}
          metalness={0}
          bumpMap={textures.grass}
          bumpScale={0.02}
        />
      </mesh>

      {/* Dirt layer under the construction area */}
      <mesh
        rotation={[-Math.PI / 2, 0, 0]}
        position={[0, -0.59, 0]}
        receiveShadow
      >
        <planeGeometry args={[gridSize + 10, gridSize + 10]} />
        <meshStandardMaterial
          color="#8B4513"
          roughness={1}
          metalness={0}
          bumpMap={textures.gravel}
          bumpScale={0.03}
        />
      </mesh>

      {/* Darker dirt border around the grid */}
      <mesh
        rotation={[-Math.PI / 2, 0, 0]}
        position={[0, -0.585, 0]}
        receiveShadow
      >
        <planeGeometry args={[gridSize + 6, gridSize + 6]} />
        <meshStandardMaterial
          color="#654321"
          roughness={1}
          metalness={0}
          bumpMap={textures.gravel}
          bumpScale={0.02}
        />
      </mesh>

      {/* Ground blocks with different states */}
      {blocks.map((row, x) =>
        row.map((block, z) => (
          <group key={`block-${x}-${z}`}>
            <mesh
              position={[
                x - gridSize / 2,
                -0.5 + GROUND_HEIGHTS[block.state] / 2,
                z - gridSize / 2,
              ]}
              castShadow
              receiveShadow
            >
              <boxGeometry args={[0.95, GROUND_HEIGHTS[block.state], 0.95]} />
              <meshStandardMaterial
                color={GROUND_COLORS[block.state]}
                metalness={block.state >= GroundState.Paved ? 0.3 : 0}
                roughness={block.state >= GroundState.Paved ? 0.7 : 0.9}
                bumpMap={
                  block.state === GroundState.Subbase
                    ? textures.gravel
                    : block.state === GroundState.Paved
                    ? textures.asphalt
                    : block.state === GroundState.Painted
                    ? textures.rolledAsphalt
                    : null
                }
                bumpScale={
                  block.state === GroundState.Subbase
                    ? 0.05
                    : block.state === GroundState.Paved
                    ? 0.02
                    : block.state === GroundState.Painted
                    ? 0.01
                    : 0
                }
              />
            </mesh>
            {block.state === GroundState.Painted && (
              <RoadMarkings
                position={[
                  x - gridSize / 2,
                  -0.5 + GROUND_HEIGHTS[block.state],
                  z - gridSize / 2,
                ]}
                roadType={getRoadConfig(x, z).type}
                rotation={getRoadConfig(x, z).rotation}
              />
            )}
            {block.hasHouse && (
              <House
                position={[
                  x - gridSize / 2,
                  -0.5 + GROUND_HEIGHTS[block.state],
                  z - gridSize / 2,
                ]}
                tier={block.buildingTier as BuildingTier}
              />
            )}
          </group>
        ))
      )}
    </group>
  );
};

export default Ground;
