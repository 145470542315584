import { useRef, useState } from "react";
import { useFrame } from "@react-three/fiber";
import { useKeyboardControls } from "@react-three/drei";
import * as THREE from "three";
import useGameStore from "../../store/gameStore";

interface EntityRefs {
  vehicleRef: React.RefObject<THREE.Group | null>;
  animationRef?: React.RefObject<THREE.Mesh | null>; // For special animations (wheels, drums, etc.)
}

interface EntityMovementConfig {
  refs: EntityRefs;
  onMove?: (delta: number) => void; // Custom animation callback
}

interface GridPosition {
  x: number;
  z: number;
  blockUpdated?: boolean;
}

const MOVEMENT_SPEED = 4; // Slower, more controlled movement

export const useEntityMovement = ({ refs, onMove }: EntityMovementConfig) => {
  const position = useGameStore((state) => state.rollerPosition);
  const moveRoller = useGameStore((state) => state.moveRoller);
  const updateBlock = useGameStore((state) => state.updateBlock);
  const gridSize = useGameStore((state) => state.gridSize);

  const [, get] = useKeyboardControls();
  const [isMoving, setIsMoving] = useState(false);
  const currentGridPos = useRef<GridPosition>({ x: 0, z: 0 });
  const visualPos = useRef<GridPosition>({ x: 0, z: 0 });
  const targetGridPos = useRef<GridPosition>({ x: 0, z: 0 });
  const movementProgress = useRef(0);

  // Initialize positions
  useFrame(() => {
    if (!isMoving) {
      currentGridPos.current = {
        x: Math.round(position.x),
        z: Math.round(position.z),
      };
      visualPos.current = { x: position.x, z: position.z };
      targetGridPos.current = {
        x: Math.round(position.x),
        z: Math.round(position.z),
      };
    }
  });

  useFrame((_, delta) => {
    if (!refs.vehicleRef.current) return;

    const { forward, backward, left, right } = get();

    // Only accept new input when not moving
    if (!isMoving && (forward || backward || left || right)) {
      let newX = currentGridPos.current.x;
      let newZ = currentGridPos.current.z;

      if (forward) newZ -= 1;
      else if (backward) newZ += 1;
      else if (left) newX -= 1;
      else if (right) newX += 1;

      // Clamp to grid boundaries
      newX = Math.max(0, Math.min(gridSize - 1, newX));
      newZ = Math.max(0, Math.min(gridSize - 1, newZ));

      // Only start movement if we're moving to a new tile
      if (
        newX !== currentGridPos.current.x ||
        newZ !== currentGridPos.current.z
      ) {
        targetGridPos.current = { x: newX, z: newZ };
        setIsMoving(true);
        movementProgress.current = 0;
      }
    }

    if (isMoving) {
      // Update movement progress
      movementProgress.current += delta * MOVEMENT_SPEED;
      movementProgress.current = Math.min(movementProgress.current, 1);

      // Smooth interpolation between current and target positions
      const t = Math.sin((movementProgress.current * Math.PI) / 2); // Smooth easing
      visualPos.current = {
        x:
          currentGridPos.current.x +
          (targetGridPos.current.x - currentGridPos.current.x) * t,
        z:
          currentGridPos.current.z +
          (targetGridPos.current.z - currentGridPos.current.z) * t,
      };

      // Update game state
      moveRoller(visualPos.current.x, visualPos.current.z);

      // Only update block when we're close to the target position
      if (
        movementProgress.current > 0.5 &&
        !currentGridPos.current.blockUpdated
      ) {
        updateBlock(targetGridPos.current.x, targetGridPos.current.z);
        currentGridPos.current.blockUpdated = true;
      }

      // Rotate vehicle towards movement direction
      const dx = targetGridPos.current.x - currentGridPos.current.x;
      const dz = targetGridPos.current.z - currentGridPos.current.z;
      refs.vehicleRef.current.rotation.y = Math.atan2(-dx, -dz);

      // Call animation callback
      if (onMove) {
        onMove(delta);
      }

      // Check if movement is complete
      if (movementProgress.current >= 1) {
        currentGridPos.current = {
          ...targetGridPos.current,
          blockUpdated: false,
        };
        setIsMoving(false);
      }
    }

    // Update visual position
    if (refs.vehicleRef.current) {
      refs.vehicleRef.current.position.set(
        visualPos.current.x - gridSize / 2,
        0,
        visualPos.current.z - gridSize / 2
      );
    }
  });

  return { isMoving };
};
