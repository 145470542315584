import { useRef, useState, useEffect } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import useGameStore from "../../store/gameStore";
import { Mesh } from "three";

const StartMarker = () => {
  const markerRef = useRef<Mesh>(null);

  useFrame((_, delta) => {
    if (markerRef.current) {
      markerRef.current.rotation.y += delta;
    }
  });

  return (
    <group>
      <pointLight position={[0, 2, 0]} color="#4CAF50" intensity={2} />
      <mesh position={[0, 2, 0]} ref={markerRef}>
        <octahedronGeometry args={[0.5]} />
        <meshStandardMaterial
          color="#4CAF50"
          emissive="#4CAF50"
          emissiveIntensity={0.5}
        />
      </mesh>
    </group>
  );
};

const EndMarker = () => {
  const markerRef = useRef<Mesh>(null);

  useFrame((_, delta) => {
    if (markerRef.current) {
      markerRef.current.rotation.y += delta;
    }
  });

  return (
    <group>
      <pointLight position={[0, 2, 0]} color="#FF4444" intensity={2} />
      <mesh position={[0, 2, 0]} ref={markerRef}>
        <octahedronGeometry args={[0.5]} />
        <meshStandardMaterial
          color="#FF4444"
          emissive="#FF4444"
          emissiveIntensity={0.5}
        />
      </mesh>
    </group>
  );
};

interface MissionMarkersProps {
  start: { x: number; z: number };
  end: { x: number; z: number };
  gridSize: number;
}

const MissionMarkers = ({ start, end, gridSize }: MissionMarkersProps) => {
  const mission = useGameStore((state) => state.currentMission);
  const completionEffectRef = useRef<THREE.Group>(null);
  const [showCompletionEffect, setShowCompletionEffect] = useState(false);

  useEffect(() => {
    if (mission?.completed) {
      setShowCompletionEffect(true);
      // Play completion sound
      const audio = new Audio("/success.mp3");
      audio.volume = 0.5;
      audio.play().catch(() => {
        // Ignore audio play errors
      });
      // Hide effect after animation
      setTimeout(() => setShowCompletionEffect(false), 2000);
    }
  }, [mission?.completed]);

  useFrame((_, delta) => {
    if (completionEffectRef.current && showCompletionEffect) {
      completionEffectRef.current.rotation.y += delta * 2;
      // Pulse the effect
      const scale = 1 + Math.sin(Date.now() * 0.01) * 0.2;
      completionEffectRef.current.scale.set(scale, scale, scale);
    }
  });

  if (!mission) return null;

  return (
    <group>
      <group position={[start.x - gridSize / 2, 0, start.z - gridSize / 2]}>
        <StartMarker />
      </group>
      <group position={[end.x - gridSize / 2, 0, end.z - gridSize / 2]}>
        <EndMarker />
      </group>

      {/* Enhanced mission completion effect */}
      {showCompletionEffect && (
        <group
          ref={completionEffectRef}
          position={[end.x - gridSize / 2, 2, end.z - gridSize / 2]}
        >
          {/* Bright central light */}
          <pointLight color="#ffff00" intensity={5} distance={8} />
          <pointLight color="#ff8800" intensity={3} distance={6} />

          {/* Inner glow */}
          <mesh>
            <sphereGeometry args={[0.8, 32, 32]} />
            <meshStandardMaterial
              color="#ffff00"
              emissive="#ffff00"
              emissiveIntensity={2}
              transparent
              opacity={0.6}
            />
          </mesh>

          {/* Outer glow */}
          <mesh>
            <sphereGeometry args={[1.2, 32, 32]} />
            <meshStandardMaterial
              color="#ff8800"
              emissive="#ff8800"
              emissiveIntensity={1}
              transparent
              opacity={0.3}
            />
          </mesh>

          {/* Sparkles */}
          {Array.from({ length: 8 }).map((_, i) => (
            <mesh
              key={i}
              position={[
                Math.cos((i * Math.PI * 2) / 8) * 1.5,
                Math.sin((i * Math.PI * 2) / 8) * 1.5,
                0,
              ]}
            >
              <sphereGeometry args={[0.1, 8, 8]} />
              <meshStandardMaterial
                color="#ffffff"
                emissive="#ffffff"
                emissiveIntensity={2}
              />
            </mesh>
          ))}
        </group>
      )}
    </group>
  );
};

export default MissionMarkers;
