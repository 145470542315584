import { useEffect } from "react";
import Ground from "./world/Ground";
import useGameStore from "../store/gameStore";
import MissionMarkers from "./world/MissionMarkers";
import Tiller from "./entities/Tiller";
import SubbaseLayer from "./entities/SubbaseLayer";
import PavementLayer from "./entities/PavementLayer";
import Roller from "./entities/Roller";
import Painter from "./entities/Painter";
import { Equipment } from "../store/gameStore";

const Game = () => {
  const initGame = useGameStore((state) => state.initGame);
  const currentEquipment = useGameStore((state) => state.currentEquipment);
  const mission = useGameStore((state) => state.currentMission);
  const gridSize = useGameStore((state) => state.gridSize);

  useEffect(() => {
    initGame();
  }, [initGame]);

  return (
    <>
      <Ground />
      {mission && (
        <MissionMarkers
          start={mission.start}
          end={mission.end}
          gridSize={gridSize}
        />
      )}
      {currentEquipment === Equipment.Tiller && <Tiller />}
      {currentEquipment === Equipment.SubbaseLayer && <SubbaseLayer />}
      {currentEquipment === Equipment.PavementLayer && <PavementLayer />}
      {currentEquipment === Equipment.Roller && <Roller />}
      {currentEquipment === Equipment.Painter && <Painter />}
    </>
  );
};

export default Game;
