import { useRef } from "react";
import * as THREE from "three";
import { useEntityMovement } from "../lib/useEntityMovement";

const SubbaseLayer = () => {
  const vehicleRef = useRef<THREE.Group>(null);
  const conveyorRef = useRef<THREE.Mesh>(null);

  useEntityMovement({
    refs: {
      vehicleRef,
      animationRef: conveyorRef,
    },
    onMove: (delta) => {
      if (conveyorRef.current) {
        conveyorRef.current.rotation.x += delta * 5;
      }
    },
  });

  return (
    <group ref={vehicleRef}>
      {/* Main hopper */}
      <mesh position={[0, 0.6, -0.4]} castShadow>
        <boxGeometry args={[1.6, 0.8, 1.0]} />
        <meshStandardMaterial color="#FFD700" />
      </mesh>

      {/* Side panels */}
      {[-0.8, 0.8].map((x) => (
        <mesh key={x} position={[x, 0.4, -0.4]} castShadow>
          <boxGeometry args={[0.1, 1.2, 1.0]} />
          <meshStandardMaterial color="#FFD700" />
        </mesh>
      ))}

      {/* Conveyor system */}
      <group position={[0, 0.4, 0.4]}>
        {/* Conveyor belt */}
        <mesh ref={conveyorRef} castShadow>
          <cylinderGeometry args={[0.2, 0.2, 1.6, 16]} />
          <meshStandardMaterial color="#444444" metalness={0.7} />
        </mesh>
        {/* Belt texture */}
        {Array.from({ length: 8 }).map((_, i) => (
          <mesh key={i} position={[0, 0, -0.8 + (i * 1.6) / 7]} castShadow>
            <boxGeometry args={[1.6, 0.21, 0.05]} />
            <meshStandardMaterial color="#333333" />
          </mesh>
        ))}
        {/* Aggregate material */}
        <mesh position={[0, 0.1, 0]} castShadow>
          <boxGeometry args={[1.6, 0.1, 0.4]} />
          <meshStandardMaterial color="#B8860B" roughness={1} />
        </mesh>
      </group>

      {/* Wheels with suspension */}
      {[-0.7, 0.7].map((x) => (
        <group key={x}>
          {/* Suspension arm */}
          <mesh
            position={[x, 0.4, 0]}
            rotation={[0, 0, Math.PI / 2]}
            castShadow
          >
            <cylinderGeometry args={[0.05, 0.05, 0.3, 8]} />
            <meshStandardMaterial color="#666666" />
          </mesh>
          {/* Wheel */}
          <mesh
            position={[x, 0.3, 0]}
            rotation={[0, 0, Math.PI / 2]}
            castShadow
          >
            <cylinderGeometry args={[0.3, 0.3, 0.2, 16]} />
            <meshStandardMaterial color="#333333" />
          </mesh>
          {/* Wheel hub */}
          <mesh
            position={[x + (x > 0 ? 0.1 : -0.1), 0.3, 0]}
            rotation={[0, 0, Math.PI / 2]}
            castShadow
          >
            <cylinderGeometry args={[0.1, 0.1, 0.05, 8]} />
            <meshStandardMaterial color="#666666" metalness={0.8} />
          </mesh>
        </group>
      ))}

      {/* Control panel */}
      <mesh position={[0, 0.9, -0.7]} castShadow>
        <boxGeometry args={[0.6, 0.2, 0.3]} />
        <meshStandardMaterial color="#333333" />
      </mesh>
    </group>
  );
};

export default SubbaseLayer;
