import { useRef } from "react";
import * as THREE from "three";
import { useEntityMovement } from "../lib/useEntityMovement";

const Tiller = () => {
  const vehicleRef = useRef<THREE.Group>(null);
  const drumRef = useRef<THREE.Mesh>(null);

  useEntityMovement({
    refs: {
      vehicleRef,
      animationRef: drumRef,
    },
    onMove: (delta) => {
      if (drumRef.current) {
        drumRef.current.rotation.x += delta * 10;
      }
    },
  });

  return (
    <group ref={vehicleRef}>
      {/* Main chassis */}
      <mesh position={[0, 0.5, 0]} castShadow>
        <boxGeometry args={[1.4, 0.4, 2.0]} />
        <meshStandardMaterial color="#FF6B00" />
      </mesh>

      {/* Operator cabin */}
      <mesh position={[0, 0.9, -0.5]} castShadow>
        <boxGeometry args={[1.2, 0.6, 0.8]} />
        <meshStandardMaterial color="#FF6B00" />
      </mesh>
      {/* Cabin windows */}
      <mesh position={[0, 0.9, -0.1]} castShadow>
        <boxGeometry args={[1.1, 0.5, 0.1]} />
        <meshStandardMaterial color="#87CEEB" opacity={0.5} transparent />
      </mesh>

      {/* Milling drum housing */}
      <mesh position={[0, 0.3, 0.6]} castShadow>
        <boxGeometry args={[1.4, 0.4, 0.8]} />
        <meshStandardMaterial color="#333333" />
      </mesh>

      {/* Milling drum with teeth */}
      <group position={[0, 0.3, 0.6]}>
        <mesh ref={drumRef} castShadow>
          <cylinderGeometry args={[0.3, 0.3, 1.4, 32, 16]} />
          <meshStandardMaterial color="#666666" metalness={0.8} roughness={0.2} />
        </mesh>
        {/* Milling teeth */}
        {Array.from({ length: 16 }).map((_, i) => (
          <group key={i} rotation={[0, (Math.PI * 2 * i) / 16, 0]}>
            <mesh position={[0, 0.3, 0]} castShadow>
              <boxGeometry args={[0.05, 0.1, 1.4]} />
              <meshStandardMaterial color="#CCCCCC" metalness={0.9} />
            </mesh>
          </group>
        ))}
      </group>

      {/* Tracks */}
      {[-0.6, 0.6].map((x) => (
        <group key={x}>
          {/* Main track body */}
          <mesh position={[x, 0.2, 0]} castShadow>
            <boxGeometry args={[0.2, 0.4, 2.0]} />
            <meshStandardMaterial color="#333333" />
          </mesh>
          {/* Track details */}
          {Array.from({ length: 8 }).map((_, i) => (
            <mesh
              key={i}
              position={[x, 0.2, -1.0 + (i * 2.0) / 7]}
              castShadow
            >
              <boxGeometry args={[0.22, 0.1, 0.1]} />
              <meshStandardMaterial color="#1C1C1C" />
            </mesh>
          ))}
        </group>
      ))}

      {/* Hydraulic arms */}
      {[-0.5, 0.5].map((x) => (
        <mesh key={x} position={[x, 0.5, 0.3]} castShadow>
          <cylinderGeometry args={[0.05, 0.05, 0.4, 8]} />
          <meshStandardMaterial color="#666666" metalness={0.6} />
        </mesh>
      ))}
    </group>
  );
};

export default Tiller;
