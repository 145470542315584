import { Equipment } from "../store/gameStore";
import useGameStore from "../store/gameStore";
import { Canvas } from "@react-three/fiber";
import { PerspectiveCamera } from "@react-three/drei";

// Static preview versions of equipment without game logic
const PreviewTiller = () => (
  <group>
    {/* Main body */}
    <group position={[0, 0.8, 0]}>
      <mesh position={[0, 0.3, -0.3]} castShadow>
        <boxGeometry args={[1.2, 0.6, 1.0]} />
        <meshStandardMaterial color="#FFA500" />
      </mesh>
      <mesh position={[0, 0.6, 0.2]} castShadow>
        <boxGeometry args={[1.0, 0.8, 0.8]} />
        <meshStandardMaterial color="#FFA500" />
      </mesh>
    </group>
    {/* Tilling drum */}
    <mesh position={[0, 0.4, 0.7]} rotation={[0, 0, Math.PI / 2]}>
      <cylinderGeometry args={[0.4, 0.4, 1.2, 32]} />
      <meshStandardMaterial color="#8B4513" metalness={0.7} roughness={0.3} />
    </mesh>
  </group>
);

const PreviewSubbaseLayer = () => (
  <group>
    <mesh position={[0, 0.2, 0]} castShadow>
      <boxGeometry args={[1.0, 0.4, 1.2]} />
      <meshStandardMaterial color="#FFD700" />
    </mesh>
    <mesh position={[0, 0.6, 0]} castShadow>
      <boxGeometry args={[1.2, 0.6, 1.4]} />
      <meshStandardMaterial color="#FFD700" />
    </mesh>
    <mesh position={[0, 0.3, 0]} rotation={[0, Math.PI / 2, 0]} castShadow>
      <boxGeometry args={[2.0, 0.4, 0.1]} />
      <meshStandardMaterial color="#B8860B" />
    </mesh>
  </group>
);

const PreviewPavementLayer = () => (
  <group>
    <mesh position={[0, 0.2, 0]} castShadow>
      <boxGeometry args={[1.6, 0.8, 1.0]} />
      <meshStandardMaterial color="#4A4A4A" />
    </mesh>
    <mesh position={[0, 0.6, 0]} castShadow>
      <boxGeometry args={[1.4, 1.0, 1.2]} />
      <meshStandardMaterial color="#FFD700" />
    </mesh>
    <mesh position={[0, 0.15, 0.8]} castShadow>
      <boxGeometry args={[1.8, 0.1, 0.4]} />
      <meshStandardMaterial color="#1C1C1C" />
    </mesh>
  </group>
);

const PreviewRoller = () => (
  <group>
    <mesh position={[0, 0.3, 0]} castShadow>
      <cylinderGeometry args={[0.6, 0.6, 1.2, 32]} />
      <meshStandardMaterial color="#1C1C1C" metalness={0.8} roughness={0.2} />
    </mesh>
    <mesh position={[0, 0.8, 0]} castShadow>
      <boxGeometry args={[0.8, 0.6, 1.0]} />
      <meshStandardMaterial color="#FFD700" />
    </mesh>
  </group>
);

const PreviewPainter = () => (
  <group>
    <mesh position={[0, 0.3, 0]} castShadow>
      <cylinderGeometry args={[0.4, 0.4, 0.8, 16]} />
      <meshStandardMaterial color="#C0C0C0" metalness={0.8} roughness={0.2} />
    </mesh>
    <mesh position={[0, 0.8, 0]} castShadow>
      <boxGeometry args={[1.0, 0.8, 0.8]} />
      <meshStandardMaterial color="#FFFFFF" />
    </mesh>
  </group>
);

const EQUIPMENT_INFO = {
  [Equipment.Tiller]: {
    name: "Milling Machine",
    Component: PreviewTiller,
  },
  [Equipment.SubbaseLayer]: {
    name: "Base Course Layer",
    Component: PreviewSubbaseLayer,
  },
  [Equipment.PavementLayer]: {
    name: "Asphalt Paver",
    Component: PreviewPavementLayer,
  },
  [Equipment.Roller]: {
    name: "Vibratory Roller",
    Component: PreviewRoller,
  },
  [Equipment.Painter]: {
    name: "Line Striper",
    Component: PreviewPainter,
  },
};

const EquipmentButton = ({
  equipment,
  isSelected,
  onClick,
}: {
  equipment: Equipment;
  isSelected: boolean;
  onClick: () => void;
}) => {
  const info = EQUIPMENT_INFO[equipment];

  return (
    <div
      className={`equipment-button ${isSelected ? "selected" : ""}`}
      onClick={onClick}
      role="button"
      aria-pressed={isSelected}
      aria-label={info.name}
    >
      <div className="equipment-button-canvas">
        <Canvas shadows>
          <ambientLight intensity={1.2} />
          <directionalLight position={[2, 2, 2]} intensity={1} />
          <PerspectiveCamera makeDefault position={[1.5, 1.5, 1.5]} fov={35} />
          <group position={[0, 0.4, 0]} scale={0.35}>
            <info.Component />
          </group>
        </Canvas>
      </div>
      <div className="equipment-button-label">{info.name}</div>
    </div>
  );
};

const EquipmentSelector = () => {
  const setEquipment = useGameStore((state) => state.setEquipment);
  const currentEquipment = useGameStore((state) => state.currentEquipment);

  return (
    <div className="equipment-selector">
      {Object.keys(EQUIPMENT_INFO).map((equipment) => (
        <EquipmentButton
          key={equipment}
          equipment={equipment as Equipment}
          isSelected={currentEquipment === equipment}
          onClick={() => setEquipment(equipment as Equipment)}
        />
      ))}
    </div>
  );
};

export default EquipmentSelector;
