import { useMemo } from "react";
import { Color } from "three";

// Define building tiers
export enum BuildingTier {
  Basic = 0, // Simple house
  Improved = 1, // Two-story house
  Luxury = 2, // Mansion
  Modern = 3, // Modern villa
  HighRise = 4, // Small apartment building
  Skyscraper = 5, // Tall building
  Capitol = 6, // Government building
}

interface HouseProps {
  position: [number, number, number];
  tier?: BuildingTier;
}

const House = ({ position, tier = BuildingTier.Basic }: HouseProps) => {
  const color = useMemo(() => {
    // Random pastel color for each building
    return new Color().setHSL(Math.random(), 0.3, 0.8);
  }, []);

  const modernColor = useMemo(() => {
    // Modern buildings use a different color scheme
    return new Color().setHSL(Math.random() * 0.1 + 0.7, 0.3, 0.8);
  }, []);

  const renderBasicHouse = () => (
    <>
      {/* Main house body */}
      <mesh castShadow position={[0, 0.5, 0]}>
        <boxGeometry args={[0.8, 1, 0.8]} />
        <meshStandardMaterial color={color} />
      </mesh>
      {/* Roof */}
      <mesh castShadow position={[0, 1.2, 0]} rotation={[0, Math.PI / 4, 0]}>
        <coneGeometry args={[0.7, 0.5, 4]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>
    </>
  );

  const renderImprovedHouse = () => (
    <>
      {/* Two-story house */}
      <mesh castShadow position={[0, 0.8, 0]}>
        <boxGeometry args={[0.9, 1.6, 0.9]} />
        <meshStandardMaterial color={color} />
      </mesh>
      {/* Windows */}
      <mesh position={[0, 0.8, 0.46]} castShadow>
        <boxGeometry args={[0.4, 0.4, 0.02]} />
        <meshStandardMaterial color="#87CEEB" opacity={0.5} transparent />
      </mesh>
      {/* Roof */}
      <mesh castShadow position={[0, 1.8, 0]} rotation={[0, Math.PI / 4, 0]}>
        <coneGeometry args={[0.8, 0.6, 4]} />
        <meshStandardMaterial color="#8B4513" />
      </mesh>
    </>
  );

  const renderLuxuryMansion = () => (
    <>
      {/* Main mansion body */}
      <mesh castShadow position={[0, 1, 0]}>
        <boxGeometry args={[1.2, 2, 1.2]} />
        <meshStandardMaterial color={color} />
      </mesh>
      {/* Side wings */}
      {[-0.8, 0.8].map((x) => (
        <mesh key={x} castShadow position={[x, 0.6, 0]}>
          <boxGeometry args={[0.6, 1.2, 0.8]} />
          <meshStandardMaterial color={color} />
        </mesh>
      ))}
      {/* Columns */}
      {[-0.4, 0.4].map((x) => (
        <mesh key={x} castShadow position={[x, 0.8, 0.65]}>
          <cylinderGeometry args={[0.1, 0.1, 1.6, 8]} />
          <meshStandardMaterial color="#FFFFFF" />
        </mesh>
      ))}
      {/* Luxury roof */}
      <mesh castShadow position={[0, 2.2, 0]} rotation={[0, Math.PI / 4, 0]}>
        <coneGeometry args={[1, 0.8, 8]} />
        <meshStandardMaterial color="#4A4A4A" />
      </mesh>
    </>
  );

  const renderModernVilla = () => (
    <>
      {/* Main structure - asymmetric design */}
      <mesh castShadow position={[0, 0.8, 0]}>
        <boxGeometry args={[1.4, 1.6, 1]} />
        <meshStandardMaterial color={modernColor} />
      </mesh>
      {/* Upper floor overhang */}
      <mesh castShadow position={[0.3, 1.4, 0.2]}>
        <boxGeometry args={[0.8, 0.8, 1.4]} />
        <meshStandardMaterial color={modernColor} />
      </mesh>
      {/* Large windows */}
      <mesh position={[0, 0.8, 0.51]} castShadow>
        <boxGeometry args={[1, 1.2, 0.02]} />
        <meshStandardMaterial color="#87CEEB" opacity={0.6} transparent />
      </mesh>
    </>
  );

  const renderHighRise = () => (
    <>
      {/* Main tower */}
      <mesh castShadow position={[0, 2, 0]}>
        <boxGeometry args={[1.2, 4, 1.2]} />
        <meshStandardMaterial color={modernColor} />
      </mesh>
      {/* Windows */}
      {Array.from({ length: 5 }).map((_, i) => (
        <mesh key={i} position={[0, 0.8 + i * 0.8, 0.61]} castShadow>
          <boxGeometry args={[1, 0.6, 0.02]} />
          <meshStandardMaterial color="#87CEEB" opacity={0.6} transparent />
        </mesh>
      ))}
    </>
  );

  const renderSkyscraper = () => (
    <>
      {/* Main tower */}
      <mesh castShadow position={[0, 3, 0]}>
        <boxGeometry args={[1, 6, 1]} />
        <meshStandardMaterial color={modernColor} />
      </mesh>
      {/* Top section */}
      <mesh castShadow position={[0, 5.5, 0]}>
        <boxGeometry args={[0.8, 1, 0.8]} />
        <meshStandardMaterial color={modernColor} />
      </mesh>
      {/* Windows */}
      {Array.from({ length: 8 }).map((_, i) => (
        <mesh key={i} position={[0, 0.8 + i * 0.8, 0.51]} castShadow>
          <boxGeometry args={[0.8, 0.6, 0.02]} />
          <meshStandardMaterial color="#87CEEB" opacity={0.7} transparent />
        </mesh>
      ))}
      {/* Antenna */}
      <mesh castShadow position={[0, 6.2, 0]}>
        <cylinderGeometry args={[0.05, 0.02, 0.8, 8]} />
        <meshStandardMaterial color="#666666" metalness={0.8} />
      </mesh>
    </>
  );

  const renderCapitol = () => (
    <>
      {/* Main building body */}
      <mesh castShadow position={[0, 1.2, 0]}>
        <boxGeometry args={[2, 2.4, 1.4]} />
        <meshStandardMaterial color="#F5F5F5" />
      </mesh>
      {/* Front columns */}
      {[-0.8, -0.4, 0, 0.4, 0.8].map((x) => (
        <mesh key={x} castShadow position={[x, 1, 0.7]}>
          <cylinderGeometry args={[0.1, 0.12, 2, 8]} />
          <meshStandardMaterial color="#FFFFFF" />
        </mesh>
      ))}
      {/* Dome */}
      <mesh castShadow position={[0, 2.6, 0]}>
        <sphereGeometry args={[0.8, 16, 16, 0, Math.PI * 2, 0, Math.PI / 2]} />
        <meshStandardMaterial color="#C0C0C0" metalness={0.4} />
      </mesh>
      {/* Steps */}
      {[0.2, 0.4, 0.6].map((y) => (
        <mesh key={y} castShadow position={[0, y * 0.2, 0.8 - y * 0.2]}>
          <boxGeometry args={[1.8, 0.1, 0.2]} />
          <meshStandardMaterial color="#DCDCDC" />
        </mesh>
      ))}
    </>
  );

  const renderBuilding = () => {
    switch (tier) {
      case BuildingTier.Basic:
        return renderBasicHouse();
      case BuildingTier.Improved:
        return renderImprovedHouse();
      case BuildingTier.Luxury:
        return renderLuxuryMansion();
      case BuildingTier.Modern:
        return renderModernVilla();
      case BuildingTier.HighRise:
        return renderHighRise();
      case BuildingTier.Skyscraper:
        return renderSkyscraper();
      case BuildingTier.Capitol:
        return renderCapitol();
      default:
        return renderBasicHouse();
    }
  };

  return <group position={position}>{renderBuilding()}</group>;
};

export default House; 
