import { useEffect, useState } from "react";
import useGameStore from "../store/gameStore";

const TouchControls = () => {
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const moveRoller = useGameStore((state) => state.moveRoller);
  const position = useGameStore((state) => state.rollerPosition);
  const gridSize = useGameStore((state) => state.gridSize);

  useEffect(() => {
    let animationFrame: number;
    let lastTime = 0;

    const updatePosition = (timestamp: number) => {
      if (!activeButton) {
        lastTime = timestamp;
        animationFrame = requestAnimationFrame(updatePosition);
        return;
      }

      const deltaTime = (timestamp - lastTime) / 1000; // Convert to seconds
      const moveSpeed = 4; // Match the movement speed in useEntityMovement
      const moveAmount = moveSpeed * deltaTime;

      let newX = position.x;
      let newZ = position.z;

      switch (activeButton) {
        case "up":
          newZ = Math.max(0, position.z - moveAmount);
          break;
        case "down":
          newZ = Math.min(gridSize - 1, position.z + moveAmount);
          break;
        case "left":
          newX = Math.max(0, position.x - moveAmount);
          break;
        case "right":
          newX = Math.min(gridSize - 1, position.x + moveAmount);
          break;
      }

      moveRoller(newX, newZ);
      lastTime = timestamp;
      animationFrame = requestAnimationFrame(updatePosition);
    };

    animationFrame = requestAnimationFrame(updatePosition);
    return () => cancelAnimationFrame(animationFrame);
  }, [activeButton, position, moveRoller, gridSize]);

  const handleTouchStart = (direction: string) => {
    setActiveButton(direction);
  };

  const handleTouchEnd = () => {
    setActiveButton(null);
  };

  // Only show on touch devices
  if (typeof window !== "undefined" && !("ontouchstart" in window)) {
    return null;
  }

  return (
    <div className="touch-controls">
      {/* Up button */}
      <div className="touch-controls-row">
        <button
          className="touch-button"
          onTouchStart={() => handleTouchStart("up")}
          onTouchEnd={handleTouchEnd}
          aria-label="Move Up"
        >
          ↑
        </button>
      </div>
      {/* Left, Down, Right buttons */}
      <div className="touch-controls-row">
        <button
          className="touch-button"
          onTouchStart={() => handleTouchStart("left")}
          onTouchEnd={handleTouchEnd}
          aria-label="Move Left"
        >
          ←
        </button>
        <button
          className="touch-button"
          onTouchStart={() => handleTouchStart("down")}
          onTouchEnd={handleTouchEnd}
          aria-label="Move Down"
        >
          ↓
        </button>
        <button
          className="touch-button"
          onTouchStart={() => handleTouchStart("right")}
          onTouchEnd={handleTouchEnd}
          aria-label="Move Right"
        >
          →
        </button>
      </div>
    </div>
  );
};

export default TouchControls; 